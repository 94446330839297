import React from "react"
import SEO from "../components/seo"
// import LayoutsComponent from '../layouts/LayoutsComponentReno';
// import AprobRenovacion from '../components/pages/afi-reno/AprobacionRenovacion'
import ValidacionIdentidad from '../components/pages/afi-reno/ValidacionIdentidad'
import LayoutsComponent from '../layouts/LayoutsComponent';

const ValidacionIdentidadPage = ({ pageContext, location }) => {
    return (
        <LayoutsComponent
            broker={pageContext.broker}
            layout={pageContext.layout}
            pageContext={pageContext}
            companyName={pageContext.company}
            location={location}
            gradient
            render={() => (
                <>
                    <SEO title={pageContext.title} />
                    <ValidacionIdentidad pageContext={pageContext} />
                </>
            )}
        />
    )
}

export default ValidacionIdentidadPage
